<template>
  <div v-capture-scroll="onScroll" v-loading="loading" class="d-flex card reconciliation-info-card">
    <div class="d-flex">
      <div class="flex-fill">
        <h4>{{ $t('reconciliationInfoCard.reconciliationInfo') }}</h4>
        <div class="d-flex flex-row mt-4">
          <div
            class="fields d-flex flex-column gap-2 text-typography-secondary"
            :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
          >
            <p>{{ $t('reconciliationInfoCard.paymentDate') }}</p>
            <p>{{ $t('reconciliationInfoCard.paymentTerms') }}</p>
            <p>{{ $t('reconciliationInfoCard.payments') }}</p>
            <p>{{ $t('reconciliationInfoCard.totalPayment') }}</p>
          </div>
          <div class="d-flex flex-column gap-2">
            <PaymentDueDate
              v-if="businessId"
              :payment-term="paymentTerm"
              :payment-due-date="reconciliationPaymentDueDate"
              :supplier="supplier"
              :payment-status="paymentStatus"
              :business-id="businessId"
              @term-saved="() => refetchData()"
            />
            <div class="d-flex align-items-center gap-1">
              <Button
                v-if="!paymentTerm || (paymentTerm && !paymentTerm.paymentMethod)"
                type="link"
                class="fw-normal p-0 add-btn"
                :disabled="!hasPurchasePaymentTermManage"
                @click.stop="openPaymentMethodModal()"
              >
                <NewIcon />
              </Button>
              <template v-else>
                <div class="position-relative" style="margin-top: -3px">
                  <el-tooltip :content="getPaymentTermTooltipContent(paymentTerm)" placement="right" :open-delay="500">
                    <span>
                      <BankTransferIcon
                        v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER"
                        width="16"
                        height="16"
                      />
                      <ChequeIcon
                        v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE"
                        width="16"
                        height="16"
                      />
                      <CreditCardIcon
                        v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD"
                        width="16"
                        height="16"
                      />
                      <RecurringIcon
                        v-if="paymentTerm.directDebit"
                        width="14"
                        height="14"
                        class="position-absolute payment-badge-position"
                      />
                    </span>
                  </el-tooltip>
                </div>
              </template>
              <PaymentDueDateButton
                v-if="businessId && (!paymentTerm || (paymentTerm && !Number.isInteger(paymentTerm.paymentDueNet)))"
                :supplier="supplier"
                :payment-term="paymentTerm"
                :business-id="businessId"
                @term-saved="refetchData"
              />
              <p v-else>
                {{
                  $tc(
                    `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm?.eom ? 'Eom' : ''}`,
                    paymentTerm?.paymentDueNet,
                    {
                      count: paymentTerm?.paymentDueNet,
                    }
                  )
                }}
              </p>
            </div>
            <BillingCountField
              :billed-amounts="reconciliation.billedAmounts"
              :billings-left-to-be-paid="billingsLeftToBePaid"
              :billing-ids="billingIds"
              :supplier="supplier"
              :customer="customer"
              :total-billed-amount="reconciliation.totalBilledAmount"
              :total-paid-amount="reconciliation.totalPaidAmount"
            />
            <TotalPaymentField
              :billed-amounts="reconciliation.billedAmounts"
              :billing-ids="billingIds"
              :supplier="supplier"
              :customer="customer"
              :total-billed-amount="reconciliation.totalBilledAmount"
              :total-paid-amount="reconciliation.totalPaidAmount"
              :unbilled-orders="reconciliation.unbilledOrders"
              truncated
            />
          </div>
        </div>
      </div>
      <div class="d-flex divider" />
      <div class="flex-fill">
        <h4>{{ $t('reconciliationInfoCard.remainingBalance') }}</h4>
        <div class="d-flex flex-row mt-4">
          <div class="date-fields d-flex gap-2 flex-column">
            <div class="d-flex align-items-center">
              <p class="text-typography-secondary">
                {{ $t('reconciliationInfoCard.toDate', { date: parseDate(reconciliation.periodEnd) }) }}
              </p>
              <el-tooltip
                :content="$t('reconciliationInfoCard.tooltips.reconciliationsBalance')"
                placement="top"
                effect="dark"
              >
                <QuestionMarkIcon :fill="'#9295A5'" :size="12" class="mx-1" />
              </el-tooltip>
            </div>
            <div class="d-flex align-items-center">
              <p class="text-typography-secondary">
                {{ $t('reconciliationInfoCard.toDate', { date: parseDate() }) }}
              </p>
              <el-tooltip :content="$t('reconciliationInfoCard.tooltips.todaysBalance')" placement="top" effect="dark">
                <QuestionMarkIcon :fill="'#9295A5'" :size="12" class="mx-1" />
              </el-tooltip>
            </div>
          </div>
          <div class="d-flex flex-column gap-2 flex-1">
            <p>{{ formatMoney(balanceToReconciliation) }}</p>
            <p>{{ formatMoney(balanceToday) }}</p>
          </div>
        </div>
      </div>
    </div>
    <PaymentMethodModal
      v-if="isPaymentMethodModalOpen"
      :bank-accounts="bankAccounts"
      @close="isPaymentMethodModalOpen = false"
      @update="handlePaymentMethodChange"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { reject, isNil, omit } from 'ramda';
import { computed, onBeforeUnmount, ref, getCurrentInstance, watch } from 'vue';

import { Button } from '@/modules/core';
import { usePayables, usePaymentTerms, useBankAccounts } from '@/modules/payment';
import { PAYMENT_STATUS, PAYMENT_STATUS_COLORS, PAYMENT_METHOD_TYPE } from '@/modules/payment/types';
import { PaymentMethodModal, useCreateTerm, useUpdateTerm } from '@/modules/term';
import {
  QuestionMarkIcon,
  NewIcon,
  BankTransferIcon,
  ChequeIcon,
  CreditCardIcon,
  RecurringIcon,
} from '@/assets/icons/';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useGlobalPermissions } from '@/modules/permissions';

import { captureScroll } from '@/directives/capture-scroll';

import PaymentDueDateButton from './PaymentDueDateButton';
import PaymentDueDate from './PaymentDueDate';
import BillingCountField from './BillingCountField';
import TotalPaymentField from './TotalPaymentField';

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    Button,
    NewIcon,
    BankTransferIcon,
    ChequeIcon,
    CreditCardIcon,
    RecurringIcon,
    QuestionMarkIcon,
    PaymentMethodModal,
    PaymentDueDate,
    PaymentDueDateButton,
    BillingCountField,
    TotalPaymentField,
  },
  directives: {
    captureScroll,
  },
  props: {
    reconciliation: { type: Object, required: true },
    reconciliationRefetch: { type: Function, required: true },
    supplier: { type: Object, required: true },
    customer: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const isPaymentMethodModalOpen = ref(false);
    const { formatToCurrency } = useCurrency({ maximumFractionDigits: 0 });

    const businessId = ref(props.reconciliation.businessId);

    watch(
      () => props.reconciliation,
      (newReconciliation) => {
        if (newReconciliation.businessId !== businessId.value) {
          businessId.value = newReconciliation.businessId;
        }
      }
    );

    const options = computed(() => ({
      enabled: !!businessId.value && !!props.supplier?.id,
    }));
    const { hasPurchasePaymentTermManage } = useGlobalPermissions();

    const {
      terms: paymentTerms,
      refetch: refetchTerms,
      loading: loadingTerm,
    } = usePaymentTerms(
      computed(() => ({
        businessId: businessId.value,
        supplierId: props.supplier.id,
      })),
      options
    );

    const { createTerm, loading: createTermLoading, onDone, onError } = useCreateTerm();

    const refetchData = async () => {
      await props.reconciliationRefetch();
      await refetchTerms();
      emit('update');
    };

    onDone(async () => {
      $message.success($i18n.t('commons.messages.action.success'));
      await sleep(2000);
      isPaymentMethodModalOpen.value = false;
      refetchData();
    });
    onError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const {
      updateTerm,
      loading: updateTermLoading,
      onDone: updateTermOnDone,
      onError: updateTermOnError,
    } = useUpdateTerm();

    const { payables: payablesToReconciliation, loading: payablesLoading } = usePayables(
      computed(() =>
        reject(isNil)({
          businessId: businessId.value,
          supplierId: props.reconciliation.supplierId,
          toDate: props.reconciliation.periodEnd,
        })
      ),
      options
    );

    updateTermOnDone(async () => {
      $message.success($i18n.t('commons.messages.action.success'));
      await sleep(2000);
      isPaymentMethodModalOpen.value = false;
      refetchData();
    });
    updateTermOnError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const { payables: payablesToday, loading: payablesTodayLoading } = usePayables(
      computed(() =>
        reject(isNil)({
          businessId: businessId.value,
          supplierId: props.supplier.id,
        })
      ),
      options
    );

    const balanceToday = computed(() => {
      return payablesToday?.value[0]?.balance ?? 0;
    });
    const balanceToReconciliation = computed(() => {
      return payablesToReconciliation?.value[0]?.balance ?? 0;
    });
    const reconciliationPaymentDueDate = computed(() => props.reconciliation?.paymentDueDate);
    const paymentTerm = computed(() => paymentTerms?.value[0]);

    const paymentStatus = computed(() => {
      if (
        reconciliationPaymentDueDate.value &&
        paymentTerm.value &&
        paymentTerm.value &&
        Number.isInteger(paymentTerm.value.paymentDueNet)
      ) {
        const currentDate = DateTime.local();
        const dueDate = DateTime.fromISO(reconciliationPaymentDueDate.value);
        if (currentDate > dueDate) return PAYMENT_STATUS.OVERDUE;
        else if (currentDate >= dueDate.minus({ days: 7 })) return PAYMENT_STATUS.APPROACHING_DUE;
        else return PAYMENT_STATUS.UNPAID;
      }
      return PAYMENT_STATUS.UNPAID;
    });

    const { bankAccounts } = useBankAccounts(computed(() => props.supplier.id));

    const loading = computed(
      () =>
        updateTermLoading.value ||
        createTermLoading.value ||
        loadingTerm.value ||
        payablesLoading.value ||
        payablesTodayLoading.value
    );

    const billingsLeftToBePaid = computed(() => {
      const paidAmountsByBillingId = props.reconciliation.paidAmounts.reduce((paidMap, paidAmount) => {
        if (paidMap[paidAmount.billingId]) paidMap[paidAmount.billingId].push(paidAmount);
        else paidMap[paidAmount.billingId] = [paidAmount];
        return paidMap;
      }, {});

      return props.reconciliation.billedAmounts.reduce(
        (billingsToPay, billedAmount) => {
          const totalPaidAmount =
            paidAmountsByBillingId[billedAmount.billingId]?.reduce((sum, paidAmount) => sum + paidAmount.amount, 0) ??
            0;

          const leftToBePaid = Math.abs(billedAmount.amount) - Math.abs(totalPaidAmount); // could be credit

          if (leftToBePaid > 0)
            billingsToPay.push({
              ...billedAmount,
              balanceDue: leftToBePaid * Math.sign(billedAmount.amount), // if credit should be negative
            });
          return billingsToPay;
        },

        []
      );
    });

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      businessId,
      hasPurchasePaymentTermManage,
      PAYMENT_STATUS,
      PAYMENT_STATUS_COLORS,
      PAYMENT_METHOD_TYPE,
      balanceToday,
      balanceToReconciliation,
      reconciliationPaymentDueDate,
      paymentTerm,
      dueDatePopoverOpen: ref(false),
      paymentStatus,
      isPaymentMethodModalOpen,
      bankAccounts,
      createTerm,
      updateTerm,
      loading,
      refetchData,
      billingsLeftToBePaid,
      formatToCurrency,
    };
  },
  computed: {
    billingIds() {
      return reject(
        isNil,
        this.reconciliation.billings.map(({ id }) => id)
      );
    },
  },
  methods: {
    onScroll() {
      this.isPaymentMethodModalOpen = false;
    },
    parseDate(isoDate) {
      const date = isoDate ? new Date(isoDate) : new Date();
      return date.toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '';
    },
    handleDueDateClick() {
      this.dueDatePopoverOpen = true;
    },
    openPaymentMethodModal() {
      this.isPaymentMethodModalOpen = true;
    },
    async handlePaymentMethodChange(newPaymentMethod) {
      if (!this.paymentTerm) {
        await this.createTerm({
          termCreateInput: reject(isNil, {
            businessId: this.businessId,
            supplierId: this.supplier.id,
            fromDate: new Date(),
            type: 'payment',
            ...newPaymentMethod,
          }),
        });
      } else {
        await this.updateTerm({
          termId: this.paymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.businessId,
            ...omit(['id', 'active', '__typename'], this.paymentTerm),
            ...newPaymentMethod,
          }),
        });
      }
    },
    getPaymentTermTooltipContent(term) {
      const paymentMethod = `payment.exports.paymentMethods.shortName.${term.paymentMethod}`;
      const directDebit = term.directDebit ? ` - ${this.$t('payment.paymentTable.directDebit')}` : '';
      return `${this.$t(paymentMethod)}${directDebit}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.reconciliation-info-card {
  padding: 20px;
}

.divider {
  margin: 0px 20px;
  padding: 0px;
  width: 1px;
  background: $outline;
}

.fields {
  width: 108px;
}

.date-fields {
  width: 124px;
}

p.text-warning.warning:not(:hover) {
  color: change-color($color: $warning, $lightness: 38.1%) !important;
}
p.text-danger.danger:not(:hover) {
  color: change-color($color: $error, $lightness: 40%) !important;
}
.payment-badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -35%);
  }
  [dir='ltr'] & {
    left: 0;
    transform: translate(-50%, -35%) scaleX(-1);
  }
}
</style>
