import { render, staticRenderFns } from "./ReconciliationInfoCard.vue?vue&type=template&id=10c90a61&scoped=true"
import script from "./ReconciliationInfoCard.vue?vue&type=script&lang=js"
export * from "./ReconciliationInfoCard.vue?vue&type=script&lang=js"
import style0 from "./ReconciliationInfoCard.vue?vue&type=style&index=0&id=10c90a61&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c90a61",
  null
  
)

export default component.exports