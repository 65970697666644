<template>
  <el-tooltip v-capture-scroll="closePopover" :disabled="!inquiriesDisabled" placement="top-end">
    <small v-if="inquiriesDisabled" slot="content">{{
      $t('reconciliation.statusReconciliationCard.noActionsPerformed')
    }}</small>
    <el-popover
      :value="visible"
      placement="bottom"
      width="400"
      :disabled="inquiriesDisabled"
      popper-class="p-0 text-typography-primary"
      trigger="manual"
    >
      <template #reference>
        <div @click.stop="togglePopover"><slot name="button" /></div>
      </template>
      <div>
        <div slot="title" class="d-flex justify-content-between align-items-center p-4 border-bottom">
          <h4>{{ $t('reconciliation.statusReconciliationCard.actionsPerformed') }}</h4>
          <Button type="icon" class="close-button" @click="closePopover">
            <CloseIcon width="20" height="20" />
          </Button>
        </div>
        <Inquiries slot="content" :inquiries="inquiries" class="p-4 performed-actions" />
      </div>
    </el-popover>
  </el-tooltip>
</template>

<script>
import { ref } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { Inquiries } from '@/modules/requests';
import { captureScroll } from '@/directives/capture-scroll';

export default {
  components: { Button, CloseIcon, Inquiries },
  directives: {
    captureScroll,
  },
  props: {
    inquiries: { type: Array, default: () => [] },
  },
  setup() {
    return { visible: ref(false) };
  },
  computed: {
    inquiriesDisabled() {
      return !this.inquiries.length;
    },
  },
  methods: {
    togglePopover() {
      this.visible = !this.visible;
    },
    closePopover() {
      this.visible = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.performed-actions {
  max-height: 400px;
  overflow: scroll;
}

.close-button {
  padding: 0;
  color: $typography-secondary;
  &:hover {
    background: #fff !important;
    color: $typography-primary !important;
  }
}

:deep(button).active {
  text-decoration: underline;
}
</style>
